import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Container from "@/components/Container"
import { ButtonBase } from "@/components/NotarizationButton"
import { ReactComponent as Logo } from "@/icons/logo.svg"

const NotFoundRoot = styled.div`
  color: #fff;
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;

  h1 {
    font-weight: 500;
    font-size: 65px;

    @media (max-width: 768px) {
      font-size: 48px;
    }

    @media (max-width: 480px) {
      font-size: 38px;
    }
  }

  p {
    font-weight: 300;
    margin: 60px 0;
    opacity: 0.7;
    max-width: 390px;

    @media (max-width: 480px) {
      margin: 42px 0;
    }
  }

  @media (max-width: 480px) {
    button {
      width: 100%;
    }
  }
`

const NotFoundImage = styled(GatsbyImage)`
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
  z-index: -2;
`

const NotFoundContainer = styled(Container)`
  height: 100%;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const NotFoundLogo = styled(Logo)`
  width: 140px;
  transition: background-color 150ms ease-in-out;
  margin-bottom: 50px;

  &:hover {
    color: ${p => p.theme.colors.primary};
  }
`

const NotFoundButton = styled(ButtonBase)`
  font-weight: 600;
  padding: 16px 32px;
  width: 185px;
  background-color: ${p => p.theme.colors.primary};
  color: ${p => p.theme.colors.black};
  transition: background-color 150ms ease-in-out;

  &:hover {
    background-color: ${p => p.theme.colors.primaryHover};
  }

  &:active {
    background-color: ${p => p.theme.colors.primaryActive};
  }
`

function NotFound() {
  const { file } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "404.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 95, layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <NotFoundRoot>
      <NotFoundImage image={file.childImageSharp.gatsbyImageData} alt="" />
      <NotFoundContainer>
        <Link to="/">
          <NotFoundLogo />
        </Link>

        <div>
          <h1>
            Oops!
            <br />
            Page not found
          </h1>
          <p>
            This page is missing or you assemble the link incorrectly. Don’t
            worry you can return to browsing just by clicking one of the above
            links.
          </p>
          <NotFoundButton as={Link} to="/">
            Go Home
          </NotFoundButton>
        </div>
      </NotFoundContainer>
    </NotFoundRoot>
  )
}

export default NotFound
